/* eslint eqeqeq: 0 */
import fetch from 'isomorphic-fetch';
// eslint-disable-next-line
import { polyfill } from 'es6-promise';
import JwtDecode from 'jwt-decode';
import numeral from 'numeral';

export const authKey = 'fcc---token';
export const settingKey = 'fcc---stumps';

const debug = process.env.REACT_APP_DEBUG;
// console.log(debug);
export const liveStatus = process.env.REACT_APP_LIVE == 'true';
export const rootURL =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_API
		: process.env.REACT_APP_LIVE_API;
export const dateLimit = process.env.REACT_APP_DATE_LIMIT;
export const ENC_Key =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_AES_128_ENCRYPT_KEY
		: process.env.REACT_APP_AES_128_ENCRYPT_KEY;
export const ENC_Vector =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_AES_128_ENCRYPT_IV
		: process.env.REACT_APP_AES_128_ENCRYPT_IV;
export const RAVE_PUBKEY =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_RAVE_PUBKEY
		: process.env.REACT_APP_RAVE_PUBKEY;

export const paymentMerchantId =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_PAYMENT_MERCHANT_ID
		: process.env.REACT_APP_PAYMENT_MERCHANT_ID;
export const paymentApiKey =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_REMITA_PAYMENT_API_KEY
		: process.env.REACT_APP_REMITA_PAYMENT_API_KEY;
export const paymentApiToken =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_REMITA_PAYMENT_API_TOKEN
		: process.env.REACT_APP_REMITA_PAYMENT_API_TOKEN;

export const merchantId = process.env.REACT_APP_REMITA_MERCHANT_ID;
export const apiKey = process.env.REACT_APP_REMITA_API_KEY;
export const apiToken = process.env.REACT_APP_REMITA_API_TOKEN;

export const bankCode =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_FROM_BANK_CODE
		: process.env.REACT_APP_FROM_BANK_CODE;
export const bankAccount =
	debug == 'true'
		? process.env.REACT_APP_DEBUG_DEBIT_ACCOUNT
		: process.env.REACT_APP_DEBIT_ACCOUNT;

export const pixelID = process.env.REACT_APP_PIXEL_ID;

export const baseURL = `${rootURL}/v1`;
export const baseURL2 = `${rootURL}/v2`;

export const settingsAPI = '/settings';
export const verifyAPI = '/auth/verify';
export const signupAPI = '/auth/signup';
export const loginAPI = '/auth/login';
export const resendEmailAPI = '/auth/resend-email';
export const changeEmailAPI = '/auth/change-email';
export const loanAPI = '/loans';
export const userAPI = '/users';
export const adminLoginAPI = '/auth/signin';
export const adminAPI = '/admin-users';
export const reportAPI = '/report';
export const walletAPI = '/wallet';
export const remitaLoanHistoryAPI = '/loan-history-request';
export const adminFindUserAPI = '/admin/find-user';
export const searchAPI = '/search';
export const supportAPI = '/supports';
export const forgotPasswordAPI = '/auth/forgot-password';
export const resetPasswordAPI = '/auth/reset-password';
export const uploadDocAPI = '/upload/attachment';
export const merchantAPI = '/merchants';
export const withdrawAPI = '/withdrawals';
export const accountAPI = '/accounts';

export const getUserID = () => {
	const date = new Date();
	const token = localStorage.getItem(authKey);
	try {
		if (token) {
			const decoded = JwtDecode(token);
			const userid = decoded.sub;
			if (decoded.exp < date.getTime()) {
				return userid;
			}
		}

		return null;
	} catch (e) {
		localStorage.removeItem(authKey);
		return null;
	}
};

const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

const headers = (auth = false) => {
	if (auth) {
		const jwt = `Bearer ${localStorage.getItem(authKey)}`;
		return { ...defaultHeaders, Authorization: jwt };
	}

	return { ...defaultHeaders };
};

export const checkStatus = async (response) => {
	if (response.ok) {
		return response;
	} else {
		if (response.statusText == 'Unauthorized') {
			// localStorage.removeItem(authKey);
			// localStorage.removeItem(settingKey);
			// window.location.reload(true);
			return;
		}

		const message = await response.text();
		const err = JSON.parse(message);
		throw Object.freeze({ message: err.error });
	}
};

const parseJSON = (response) => {
	return response.json();
};

export const httpRequest = (url, method, auth, data) => {
	const body = JSON.stringify(data);
	return fetch(url, {
		method: method,
		headers: headers(auth),
		body: body,
	})
		.then(checkStatus)
		.then(parseJSON);
};

export const getTitle = (status) => {
	let message = '';
	switch (status) {
		case 'red':
			message = 'declined';
			break;
		case 'green':
			message = 'approved';
			break;
		case 'yellow':
			message = 'pending';
			break;

		default:
			message = '';
			break;
	}
	return message;
};

export const getPTitle = (status) => {
	let message = '';
	switch (status) {
		case 'green':
			message = 'Completed';
			break;
		case 'yellow':
			message = 'Pending';
			break;

		default:
			message = '';
			break;
	}
	return message;
};

export const httpRequest2 = (url, method, body) => {
	const jwt = `Bearer ${localStorage.getItem(authKey)}`;
	const headers = {
		Accept: 'application/json, */*',
		Authorization: jwt,
	};
	return fetch(url, {
		method: method,
		headers: headers,
		body: body,
	})
		.then(checkStatus)
		.then(parseJSON);
};

export const currency = (amount) => `₦${numeral(amount).format('0,0.00')}`;

export const mask = (str) => {
	if (str !== '0') {
		const maskLen = str.length - 4;
		const last4 = str.substr(maskLen);
		const masks = [...Array(maskLen).keys()].map((_) => '*').join('');
		return `${masks}${last4}`;
	}

	return '';
};
