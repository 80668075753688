import * as types from './types';
import { setCurrentLoan, setLoans } from './loan';
import { setLenderProfile } from './lender';
import { loadBanks } from './general';

export const toggleRptMenu = (action) => {
	return {
		type: types.TOGGLE_RPT_MENU,
		payload: action,
	};
};

export const signOut = () => {
	return {
		type: types.SIGN_OUT,
	};
};

export const startPreloading = () => {
	return {
		type: types.START_PRELOADING,
	};
};

export const stopPreloading = () => {
	return {
		type: types.STOP_PRELOADING,
	};
};

export const setAccountNumber = (data) => {
	return {
		type: types.SET_ACCOUNT_NUMBER,
		payload: data,
	};
};

export const setPhoneNumber = (data) => {
	return {
		type: types.SET_PHONE_NUMBER,
		payload: data,
	};
};

export const setPhone = (phone) => {
	return {
		type: types.SET_PHONE,
		payload: phone,
	};
};

const setEarnings = (data) => {
	return {
		type: types.SET_EARNINGS,
		data,
	};
};

export const changeUserData = (user, status) => {
	return {
		type: types.CHANGE_USER_DATA,
		user,
		status,
	};
};

export const setPayslipData = (data) => {
	return {
		type: types.SET_PAYSLIP_INFO,
		payload: data,
	};
};

export const setRemitaData = (data) => {
	return {
		type: types.SET_REMITA_INFO,
		payload: data,
	};
};

export const setBankAccounts = (payload) => {
	return {
		type: types.ADD_ACCOUNTS,
		payload,
	};
};

export const newBankAccount = (payload) => {
	return {
		type: types.ADD_ACCOUNT,
		payload,
	};
};

export const setCurrentUser = (user) => {
	return (dispatch) => {
		const {
			payslip,
			lender,
			current_loan,
			loans,
			earning,
			bank_accounts,
			banks,
			...info
		} = user;
		dispatch(changeUserData(info, true));
		dispatch(setBankAccounts(bank_accounts));
		dispatch(setPayslipData(payslip));
		dispatch(setEarnings(earning));
		dispatch(setCurrentLoan(current_loan));
		dispatch(setLoans(loans));
		dispatch(setLenderProfile(lender));
		dispatch(loadBanks(banks));
	};
};

export const updateUserLoan = () => {
	return {
		type: types.UPDATE_USER_LOAN_DATA,
	};
};

export const setUserData = (id, email) => {
	return {
		type: types.RESEND_EMAIL,
		payload: { user_id: id, user_email: email },
	};
};

export const updateWallet = (data) => {
	return {
		type: types.UPDATE_WALLET,
		payload: data,
	};
};

export const updateUser = (data) => {
	return {
		type: types.UPDATE_USER,
		payload: data,
	};
};
